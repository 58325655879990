export function indexTasksInSchedule(schedule) {
    let index = 1
    schedule.tasks?.forEach((task, sort) => {
        task.taskId ??= task.id
        task.$sort = sort
        if (task.isHeader) return
        task.$index = index++
    })
    return schedule
}
