import { useReferenceState } from "lib/@hooks/use-reference"
import { List, Stack, Typography } from "@mui/material"
import { Repeat } from "lib/@components/repeat"
import { RecentThreeWords } from "companion/routes/whatwords/recent-three-words"

export function RecentWords() {
    const [list] = useReferenceState("word-memory", [])
    return (
        !!list?.length && (
            <Stack spacing={1} sx={{ pt: 3 }}>
                <Typography variant="subtitle2">Recent Words</Typography>
                <List>
                    <Repeat list={list} item={<RecentThreeWords />} />
                </List>
            </Stack>
        )
    )
}
