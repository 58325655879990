import { api } from "lib/graphql/api"
import { mutate } from "lib/graphql/mutate"
import { gql } from "@apollo/client"
import { registerOfflineAction } from "lib/@offline/register-offline-action"

const reportGql = api(async function reportIssue({ shareLinkId, taskId, report, attachments, metadata, reportDate }) {
    return mutate(
        gql`
            mutation API_ReportIssue(
                $shareLinkId: String!
                $taskId: String!
                $report: String!
                $attachments: [JSONObject!]
                $metadata: JSONObject
                $reportDate: Date!
            ) {
                reportIssue(
                    shareLinkId: $shareLinkId
                    taskId: $taskId
                    report: $report
                    attachments: $attachments
                    metadata: $metadata
                    reportDate: $reportDate
                )
            }
        `,
        {
            shareLinkId,
            taskId,
            report,
            attachments,
            metadata,
            reportDate,
        },
        {
            returns: "reportIssue",
        }
    )
})

export const reportIssue = registerOfflineAction(reportGql, "reportIssue")
