import noop from "lib/noop"
import { useState } from "react"
import { generate } from "library/guid"
import { NotOnMobile, OnMobile, useMobile } from "lib/@components/mobile"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { indexTasksInSchedule } from "routes/schedule/lib/index-tasks-in-schedule"
import { DashboardHeaderLeft } from "slot-definitions"
import { Box, Divider, Fab, Stack, Typography } from "@mui/material"
import { Bound } from "lib/@components/binding/Bound"
import { Watermark } from "lib/@components/watermark/watermark"
import { UpBar } from "routes/schedule/components/up-bar"
import { ScheduleTitle } from "routes/schedule/scheduleTitle"
import { ScheduleBottom, ScheduleIntroduction, ScheduleTopmost } from "routes/schedule/scheduleIntroduction"
import { TaskListDisplay } from "routes/schedule/tasks/taskListDisplay"
import { navigate } from "lib/routes/navigate"
import { Repeat } from "lib/@components/repeat"
import { Introduction } from "companion/routes/tasks/introduction"
import { useSearchParam } from "lib/@hooks/use-search-param"
import Iconify from "minimals-template/components/Iconify"

export function ScheduleCompanionDetails({ rendered = noop }) {
    const [id] = useState(generate)
    const [selections] = useState({})
    const isMobile = useMobile()
    const { schedule, introductions } = useBoundContext()
    const [returnTo] = useSearchParam("returnTo")

    indexTasksInSchedule(schedule)

    DashboardHeaderLeft.usePlug(
        <Typography
            variant="body2"
            sx={{ color: "text.primary", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
        >
            #{schedule.code}: {schedule.title}
        </Typography>
    )
    return (
        <Bound allSelected={selections} key={id} isPreview>
            {!!schedule.retired && <Watermark text="Retired" />}
            <Stack key={schedule._id} sx={{ height: 1, overflowY: "auto", mt: 1 }} ref={rendered}>
                <Box sx={{ position: "fixed", pointerEvents: "all", zIndex: 1000, right: 30, bottom: 30 }}>
                    {returnTo ? (
                        <Fab onClick={go} variant="extended" size="medium">
                            <Iconify icon="mdi:arrow-back" />
                            Back
                        </Fab>
                    ) : (
                        <Fab onClick={go} variant="extended" size="medium">
                            <Iconify icon="mdi:search" />
                            Lookup Another
                        </Fab>
                    )}
                </Box>
                <UpBar inContainer={true} url="/app" />
                <NotOnMobile>
                    <Stack
                        sx={{
                            flex: 1,
                            alignItems: "stretch",
                            overflow: "hidden",
                            flexGrow: 1,
                            mx: isMobile ? -1.5 : 0,
                        }}
                        direction="row"
                        spacing={0}
                    >
                        <Box
                            className="schedule-details styled-bullets"
                            sx={{
                                overflowX: "hidden",
                                pr: 0,
                                width: 1,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <ScheduleTitle schedule={schedule} />
                            <ScheduleTopmost.Slot schedule={schedule} />
                            <Repeat list={introductions} item={<Introduction />} />
                            <Divider />
                            {Object.values(selections).filter(Boolean).length === 0 && (
                                <ScheduleIntroduction schedule={schedule} />
                            )}
                            <TaskListDisplay schedule={schedule} tasks={schedule.tasks} />
                            <ScheduleBottom.Slot schedule={schedule} />

                            <Box mt={50} />
                        </Box>
                    </Stack>
                </NotOnMobile>
                <OnMobile>
                    <Box
                        className="schedule-details styled-bullets"
                        sx={{
                            flexGrow: 1,
                            overflowX: "hidden",
                            overflowY: "scroll",
                            width: 1,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <ScheduleTitle schedule={schedule} />
                        <Repeat list={introductions} item={<Introduction />} />
                        <Divider />
                        {Object.values(selections).filter(Boolean).length === 0 && (
                            <ScheduleIntroduction schedule={schedule} />
                        )}
                        <TaskListDisplay schedule={schedule} tasks={schedule.tasks} />
                        <ScheduleBottom.Slot schedule={schedule} />
                        <Box mt={50} />
                    </Box>
                </OnMobile>
            </Stack>
        </Bound>
    )

    function go() {
        if (returnTo) {
            window.history.back()
        } else {
            navigate("/sfg20")
        }
    }
}
