import React, { useEffect, useRef, useState } from "react"
import { Box, List, ListItem, useTheme } from "@mui/material"
import { BusyAdd, BusyHidden, BusyRemoved, Freezer } from "event-definitions"
import { getLastRenderTime, getLastStack } from "lib/render-trap"
import { RealLoadingScreen } from "minimals-template/components/LoadingScreen"
import { getStackFrameLocations } from "lib/@debug/browser-cls/get-stack-frame-locations"
import { createEvent } from "library/local-events"
import { CONFIGURABLE_BUSY_TIMEOUT } from "lib/@components/busy/busy-while-with-delay"

const DEBUG_BUSY = process.env.REACT_APP_DEBUG_BUSY ?? false
// This is to self terminate the busy, we think we may have a race on BUSY/REMOVED in the tests.

let currentlyBusy = false

const BusyShown = createEvent("BusyShown")

export function isBusyVisible() {
    return currentlyBusy
}

let willShowBusy = false
let isBusyShown = false

export async function waitUntilBusyShown() {
    return isBusyShown ? true : BusyShown.wait(willShowBusy ? 1500 : 800)
}

const runningIds = {}

export function BusyComponent() {
    const theme = useTheme()
    const showTimer = useRef()
    const showTimeout = useRef(0)
    const [busyList, setBusy] = useState({})
    const lastTime = useRef()

    const [shouldShow, setShouldShow] = useState(false)

    window.showBusy = () => console.log(busyList)
    window.notBusy = () => {
        setBusy({})
        hide()
    }

    BusyAdd.useEvent(
        ({ id, description, opacity = 0.24 }) => {
            if (window.Cypress) {
                clearTimeout(runningIds[id])
                runningIds[id] = setTimeout(() => BusyRemoved.raise({ id }), CONFIGURABLE_BUSY_TIMEOUT)
            }
            DEBUG_BUSY && console.log("Added", id, { stack: getStackFrameLocations(2) })
            clearTimeout(showTimeout.current)
            Freezer.raise(true)
            setShouldShow(true)
            setBusy((busy) => ({ ...busy, [`_busy_${id}`]: DEBUG_BUSY ? getLastStack() : description ?? " ", opacity }))
        },
        [setBusy]
    )
    BusyRemoved.useEvent(
        ({ id }) => {
            clearTimeout(runningIds[id])
            setBusy((busy) => {
                const newBusy = { ...busy }
                delete newBusy[`_busy_${id}`]
                if (Object.keys(newBusy).filter((c) => c.startsWith("_busy_")).length === 0) {
                    lastTime.current = Date.now()
                    if (window.Cypress) {
                        hide()
                    } else {
                        showTimeout.current = setTimeout(hide, 50)
                    }
                    delete newBusy.opacity
                }
                DEBUG_BUSY && console.log("Removed", id, Object.keys(newBusy))
                return newBusy
            })
        },
        [setBusy]
    )
    const { opacity } = busyList
    delete busyList.opacity
    currentlyBusy = shouldShow

    useEffect(() => {
        if (shouldShow) {
            willShowBusy = true
            isBusyShown = false
            showTimer.current = setTimeout(() => {
                willShowBusy = false
                isBusyShown = true
                BusyShown.raiseOnce()
            }, 150)
        }
        return () => {
            willShowBusy = false
            isBusyShown = false
            clearTimeout(showTimer.current)
        }
    }, [shouldShow])

    const isBusy = Object.keys(busyList).filter((c) => c.startsWith("_busy_")).length !== 0
    return (
        <Box
            id={isBusy ? "busy" : "disabledBusy"}
            sx={{
                position: "fixed",
                left: 0,
                top: 0,
                right: 0,
                width: "100vw",
                height: "100vh",
                zIndex: 30000,
                pointerEvents: isBusy ? "all" : "none",
                flexDirection: "column",
                opacity: shouldShow ? 1 : 0,
                transition: "opacity linear 0.2s, backdrop-filter linear 0.2s, background-color linear 0.2s",
                backdropFilter: shouldShow ? `blur(6px)` : "blur(0px)",
                backgroundColor: shouldShow
                    ? `${theme.palette.background.default}${theme.palette.background.default.length < 5 ? "b" : "b0"}`
                    : undefined,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {shouldShow && (
                <RealLoadingScreen opacity={opacity}>
                    <List>
                        {Object.values(busyList)
                            .unique()
                            .map((child, index) => (
                                <ListItem key={index}>{child}</ListItem>
                            ))}
                    </List>
                </RealLoadingScreen>
            )}
        </Box>
    )

    function hide() {
        if (!window.Cypress && Date.now() - lastTime.current < 3000 && Date.now() - getLastRenderTime() < 200) {
            if (DEBUG_BUSY) {
                console.log(getLastStack())
            }
            showTimeout.current = setTimeout(hide, 50)
        } else {
            Freezer.raiseLater(false)
            BusyHidden.raiseLater(true)
            setShouldShow(false)
        }
    }
}
