import { useEffect, useRef, useState } from "react"
import { Bound } from "lib/@components/binding/Bound"
import { ListItemBox } from "lib/@components/ListItemBox"
import { Box, Button, Link, List, Typography } from "@mui/material"
import { Repeat } from "lib/@components/repeat"
import { TreeItem } from "companion/routes/tasks/tree-item"
import { StandardBreadcrumbs } from "lib/@components/standardBreadcrumbs"
import { ensureArray } from "lib/ensure-array"
import { createPortalPair } from "lib/portals"
import { TreeListPage } from "slot-definitions"
import { RestoreScrollBox } from "lib/use-scroll-restoration"
import { Searcher } from "companion/routes/tasks/searcher"
import { useObjectSearchParam, useSearchParam } from "lib/@hooks/use-search-param"
import { navigate } from "lib/routes/navigate"
import Logo from "minimals-template/components/Logo"
import { ReactComponent as FacilitiesLogo } from "routes/@assets/Icon - Facilities-iQ.svg"
import { addTempBusy } from "lib/@components/busy/add-temp-busy"
import Iconify from "minimals-template/components/Iconify"

export const [TreeListPortal, TreeListUser] = createPortalPair()
export const [TreeBottomPortal, TreeBottomUser] = createPortalPair()

export function TreeList({ tree }) {
    const first = useRef(true)
    const { $index } = tree
    const [parentOverride, setParentOverride] = useState(null)
    const [stack, setStack] = useObjectSearchParam("stack", [])
    const [currentId, setCurrentId] = useSearchParam("current", tree.id)
    const current = $index[currentId]

    useEffect(() => {
        if (!current && !Object.isEmpty($index)) {
            if (first.current) {
                first.current = false
                return
            }
            setCurrentId(tree.id)
            setStack([])
        }
    }, [current, setCurrentId, setStack, tree, $index])

    return (
        !!current && (
            <Bound
                go={go}
                back={back}
                replace={replace}
                current={current}
                stack={stack}
                realParent={$index[stack.at(-1)]}
                index={$index}
                parent={parentOverride || $index[stack.at(-1)]}
                target={current}
                setParentOverride={setParentOverride}
            >
                <StandardBreadcrumbs>
                    {console.log({ stack })}
                    {stack
                        .filter((s) => !!$index[s]?.value)
                        .map((c, index) => (
                            <Link onClick={reverseTo(index)} key={index}>
                                {ensureArray($index[c].value).at(-1)}
                            </Link>
                        ))}
                    <Typography
                        variant="caption"
                        sx={{
                            display: "block",
                            fontWeight: "bold",
                            maxWidth: 250,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {ensureArray(current.value ?? current.title).at(-1)}
                    </Typography>
                </StandardBreadcrumbs>

                <ListItemBox minHeight={56} px={1}>
                    {!!stack.length && (
                        <Button startIcon={<Iconify icon="mdi:arrow-back" />} onClick={back}>
                            Back
                        </Button>
                    )}
                    {!stack.length && (
                        <Button variant="contained" startIcon={<Iconify icon="mdi:arrow-back" />} onClick={words}>
                            Words
                        </Button>
                    )}

                    <Box flex={1} />
                    <Box>
                        <TreeListPortal />
                    </Box>
                </ListItemBox>
                <RestoreScrollBox flex={1} key={current.id} scrollKey={current.id}>
                    <TreeListPage.Slot type="searcher">
                        <Searcher />
                    </TreeListPage.Slot>
                    <TreeListPage.Slot>
                        <List>
                            <Repeat
                                list={current.children?.sortBy([
                                    "type",
                                    current.children[0]?.sortOrder || ((c) => (c.children ? 0 : 1)),
                                ])}
                                item={<TreeItem />}
                                bind
                            />
                        </List>
                    </TreeListPage.Slot>
                </RestoreScrollBox>
                <TreeBottomPortal />

                <ListItemBox px={4}>
                    <ListItemBox mt={-1.5} flex={1}>
                        <Logo sx={{ height: 20, mt: 1 }} />
                        <Box flex={1} />
                        <Box pt={2}>
                            <FacilitiesLogo height={30} />
                        </Box>
                    </ListItemBox>
                </ListItemBox>
            </Bound>
        )
    )

    function reverseTo(index) {
        return function go() {
            addTempBusy()
            setCurrentId(stack[index])
            setStack((s) => s.slice(0, index))
        }
    }

    function go(item) {
        addTempBusy()
        setStack((s) => [...s, currentId])
        setCurrentId(item.id)
    }

    function replace(item) {
        addTempBusy()
        setCurrentId(item.id)
    }

    function back() {
        addTempBusy()
        setStack((s) => {
            setCurrentId(s.at(-1))
            return s.slice(0, -1)
        })
    }

    function words() {
        navigate("/sfg20")
    }
}
