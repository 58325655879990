import { Box, Typography } from "@mui/material"
import { ScheduleIntroduction } from "routes/schedule/scheduleIntroduction"
import { Bound } from "lib/@components/binding/Bound"
import { TaskList } from "./scheduleDetails"
import { SchedulePDFHeader } from "./schedule-pdf-header"
import { BusyHidden } from "event-definitions"
import { delay } from "lib/delay"
import { PrintableStack } from "lib/@components/pdf/printable-stack"
import { Watermark } from "lib/@components/watermark/watermark"
import { getClientProfile } from "routes/client-admin/plugins/profile/controller/get-client-profile"
import LoadingScreen from "minimals-template/components/LoadingScreen"

export function SchedulePDFDisplay({ schedule, id }) {
    const { data: profile, loading } = getClientProfile.useResults.status()

    BusyHidden.useEvent(async (hidden) => {
        if (hidden) {
            const searchParams = new URLSearchParams(window.location.search)
            const shouldPrint = searchParams.has("print")
            if (shouldPrint) {
                await delay(500)
                window.print()
            }
        }
    })

    if (loading) return <LoadingScreen />

    return (
        <Bound key={id}>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <PrintableStack key={schedule._id}>
                                {!!schedule.retired && <Watermark text="Retired" sx={{ position: "fixed" }} />}
                                <Box
                                    className="schedule-details styled-bullets"
                                    sx={{
                                        overflowX: "hidden",
                                        pr: 0,
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <SchedulePDFHeader schedule={schedule} profile={profile} />
                                    <ScheduleIntroduction schedule={schedule} />
                                    <TaskList schedule={schedule} />
                                </Box>
                            </PrintableStack>
                        </td>
                    </tr>
                </tbody>
                <tfoot className="print-footer-container" />
            </table>
            <table>
                <tbody className="print-footer">
                    <tr>
                        <td>
                            <Box sx={{ lineHeight: 1 }}>
                                <Typography variant="caption" color="text.secondary">
                                    &copy; BESA Publications Limited 2025. All rights reserved. This document was
                                    prepared for {profile?.name} and remains commercial in confidence. SFG20&reg; and
                                    Facilities-iQ&reg; are registered trade marks of BESA Publications Limited.
                                </Typography>
                            </Box>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Bound>
    )
}
