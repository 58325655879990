import { useReferenceState } from "lib/@hooks/use-reference"
import { useEffect } from "react"

export function useRecordWordCombination({ title, words, icon }) {
    words ??= window.location.href
        .split("?")[0]
        .split("/")
        .filter((d) => d !== "details")
        .slice(-3)
    const [list, setList] = useReferenceState("word-memory", [])
    useEffect(() => {
        const currentKey = words.join("/")
        setList((wordList) =>
            [
                {
                    icon,
                    key: currentKey,
                    title: title.replaceAll("<words>", words.join(" ")),
                    href: window.location.pathname,
                    words,
                },
                ...wordList.filter((w) => w.key !== currentKey),
            ].slice(0, 10)
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...words, title])
    return list
}
