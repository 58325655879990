import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material"
import { colors } from "companion/routes/schedule/colors"
import Iconify from "minimals-template/components/Iconify"
import { icons } from "companion/routes/schedule/icons"
import { ensureArray } from "lib/ensure-array"
import { getAllTasksInTree } from "companion/routes/tasks/get-all-tasks"
import { useMemo } from "react"
import { completed } from "companion/routes/schedule/use-task-completions"
import { ListItemBox } from "lib/@components/ListItemBox"
import { getReference } from "lib/@hooks/use-reference"
import { completedToday } from "companion/routes/schedule/use-completed"

import { getCompletionKey } from "library/get-completion-key"

export function TreeHeader({ item }) {
    const { go } = useBoundContext()
    const { toBeDone, totalTasks } = useMemo(() => {
        const reference = getReference()
        const tasks = getAllTasksInTree(item).unique((c) => getCompletionKey(c.id))
        return {
            toBeDone: tasks.reduce(
                (count, task) =>
                    !task[completed] && !completedToday(reference[getCompletionKey(task.id)]) ? count + 1 : count,
                0
            ),
            totalTasks: tasks.length,
        }
    }, [item])
    return (
        <ListItemButton onClick={() => go(item)}>
            <ListItemBox>
                <ListItemIcon sx={{ color: colors[item.type] ?? "primary.main" }}>
                    <Iconify icon={icons[item.type]} />
                </ListItemIcon>
                <ListItemText primary={ensureArray(item.value).join(" > ")} />
                <Typography component="div" variant="caption" color="text.secondary">
                    <strong>{toBeDone}</strong>/{totalTasks}
                </Typography>
            </ListItemBox>
        </ListItemButton>
    )
}
