import { Button } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useLocation } from "react-router"
import { parentFind } from "library/tree"
import { PlanRoot } from "library/tree/constants"

export function DownloadSchedulePDFButton() {
    const { schedulePath, parentItem } = useBoundContext()
    const { search } = useLocation()
    const planRoot = parentFind(parentItem, (c) => c[PlanRoot])

    return (
        !!planRoot && (
            <Button
                variant="outlined"
                size="small"
                onClick={handlePdfDownload}
                startIcon={<Iconify icon="ic:round-print" />}
                data-cy="download-schedule-pdf-button"
                sx={{ mr: 2.5 }}
            >
                Print Schedule
            </Button>
        )
    )

    async function handlePdfDownload() {
        const scheduleURL = encodeURI(schedulePath + search)
        const url = `${window.location.protocol}//${window.location.host}/pdf/facilities/schedule/${scheduleURL}&schedulePDF=true&print=true`
        window.open(url, "_blank")
    }
}
