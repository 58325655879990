import { Box } from "@mui/material"
import { useContext, useEffect, useLayoutEffect, useMemo } from "react"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { DragSetOverlay } from "event-definitions"
import { DragContext } from "lib/DragContext"
import { useSearchParam } from "lib/@hooks/use-search-param"

export function SortableItem({ Component = Box, disabled, setDragProps, id, children, ...props }) {
    const { active, setDragOverlay } = useContext(DragContext)
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: `sort_${id}`,
        disabled,
    })
    const [schedulePDF] = useSearchParam("schedulePDF")
    const style = {
        transform: active ? CSS.Transform.toString(transform) : undefined,
        transition: active ? transition : undefined,
        opacity: `sort_${id}` === active?.id ? 0.2 : 1,
    }
    useEffect(() => {
        if (active?.id === `sort_${id}`) {
            setDragOverlay(<Component {...props}>{children}</Component>)
            DragSetOverlay.raiseLater()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active?.id, id, setDragOverlay])
    const dragProps = useMemo(() => ({ ...attributes, ...listeners }), [attributes, listeners])
    useLayoutEffect(() => {
        setDragProps?.(dragProps)
    }, [dragProps, setDragProps])
    return (
        <Component
            sx={{ "&:focus-visible": { outline: "none" } }}
            style={style}
            {...props}
            {...(setDragProps ? {} : dragProps)}
            className={`${props.className} ${!schedulePDF ? "keep-together" : ""}`}
            ref={setNodeRef}
            tabIndex={-1}
        >
            {children}
        </Component>
    )
}
