import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { navigate } from "lib/routes/navigate"

export function RecentThreeWords({ item }) {
    return (
        <ListItemButton onClick={go}>
            <ListItemIcon>
                <Iconify icon={item.icon ?? "fluent:cloud-words-32-regular"} />
            </ListItemIcon>
            <ListItemText sx={{ color: "primary.main", fontWeight: "bold" }} primary={item.title} />
        </ListItemButton>
    )

    function go() {
        navigate(item.href)
    }
}
