import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Box, Button, ButtonGroup, Divider, IconButton, List, Stack, Typography } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { COLOR_FROM_CLASSIFICATION, ICON_FROM_CLASSIFICATION } from "library/SFG20/constants"
import { Repeat } from "lib/@components/repeat"
import { ListItemBox } from "lib/@components/ListItemBox"
import { alpha } from "@mui/material/styles"
import uniqolor from "uniqolor"
import { TreeBottomUser } from "companion/routes/tasks/tree-list"
import { TreeListPage } from "slot-definitions"
import { Frag } from "lib/@components/slot/frag"
import { navigate } from "lib/routes/navigate"
import { useMemo, useState } from "react"
import { Bound } from "lib/@components/binding/Bound"
import { Step } from "companion/routes/schedule/step"
import { useDialog } from "lib/@hooks/useDialog"
import { CompleteDialog } from "companion/routes/tasks/complete-dialog"
import { useReferenceState } from "lib/@hooks/use-reference"
import { Watermark } from "lib/@components/watermark/watermark"
import { ReportDialog } from "companion/routes/tasks/report-dialog"
import { alert } from "lib/@dialogs/alert"
import { completeTask } from "companion/routes/schedule/controller/complete-task"
import { useCompleted } from "companion/routes/schedule/use-completed"
import { BadgeLabel } from "routes/regime/plugins/regime-title/badge-label"
import { reportIssue } from "companion/routes/schedule/controller/report-issue"

import { getCompletionKey } from "library/get-completion-key"

export function TaskDetails() {
    const { target, replace, parent, shareLinkId } = useBoundContext()
    const completed = useCompleted(target)
    const [, setLastCompleteTime] = useReferenceState(getCompletionKey(target.id), 0)
    const [favourites, setFavourites] = useReferenceState("taskFavourite", {})
    const isFavourite = favourites[target.id]
    const index = parent.children.indexOf(target)
    const [allSelected, setAllSelected] = useState({})
    useMemo(() => {
        setAllSelected({})
        // eslint-disable-next-line
    }, [target.id])
    const canComplete = !Object.isEmpty(allSelected) && Object.values(allSelected).every((v) => !!v)

    const title = (
        <>
            <Typography variant="subtitle2" color="text.secondary" sx={{ opacity: 0.7 }}>
                {target.where}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary" component="div">
                <ListItemBox>
                    <Box>
                        {target.schedule.title} #{target.schedule.code}
                    </Box>
                    <Box flex={1} />
                    <Box sx={{ whiteSpace: "nowrap" }}>
                        <Typography variant="caption" color="text.secondary">
                            Every {target.frequency.interval}{" "}
                            {target.frequency.period.pluralize(target.frequency.interval)}
                        </Typography>
                    </Box>
                </ListItemBox>
            </Typography>
            <Typography variant="subtitle1" component="div">
                <ListItemBox spacing={2}>
                    <Box>
                        <Iconify
                            icon={ICON_FROM_CLASSIFICATION[target.classification]}
                            sx={{ color: COLOR_FROM_CLASSIFICATION[target.classification] }}
                        />
                    </Box>
                    <Box>{target.title}</Box>
                    <Box flex={1} />
                    <Box
                        sx={{
                            whiteSpace: "nowrap",
                            borderRadius: 1,
                            py: 0.5,
                            px: 1.5,
                            bgcolor: alpha(uniqolor(target.skill.SkillingCode).color, 0.3),
                        }}
                    >
                        <Typography variant="body2" component="div">
                            {target.skill.Skilling}
                        </Typography>
                    </Box>
                </ListItemBox>
            </Typography>
        </>
    )
    const sign = useDialog(<CompleteDialog title={title} />)
    const report = useDialog(<ReportDialog title={title} />)

    return (
        <Bound completed={completed} allSelected={allSelected} setAllSelected={setAllSelected}>
            <Stack spacing={1} sx={{ p: 1, mt: 1, flex: 1 }} className="styled-bullets">
                {completed && (
                    <Watermark text="Completed" sx={{ opacity: 0.3, textAlign: "center" }} color="error.main" />
                )}

                <TreeListPage.Plug type="searcher" replace>
                    <Frag />
                </TreeListPage.Plug>
                {title}
                <Box py={1}>
                    <List>
                        <Repeat list={target.steps} item={<Step />} />
                    </List>
                </Box>
                <Box pb={8} />
                <Divider />
                <Box width={1}>
                    <Box m="auto" maxWidth={400}>
                        <Typography variant="body2" color="text.secondary" sx={{ px: 3 }}>
                            This check list is a reminder of the actions to take, you should be fully familiar with the
                            actual schedule, the complete task description and any introductions or preliminary
                            procedures.
                        </Typography>
                    </Box>
                </Box>
                <Button onClick={showSchedule}>View Schedule</Button>
                <TreeBottomUser>
                    <Box sx={{ p: 1 }}>
                        <ListItemBox spacing={1}>
                            <IconButton
                                onClick={() => replace(parent.children[index - 1])}
                                disabled={index === 0}
                                color="primary"
                            >
                                <Iconify icon="mdi:arrow-left" />
                            </IconButton>
                            {target.schedule.assets.length > 1 && (
                                <Typography variant="body2" color="text.secondary" component="div">
                                    <BadgeLabel>
                                        {target.schedule.assets.length}{" "}
                                        {"asset".pluralize(target.schedule.assets.length)}
                                    </BadgeLabel>
                                </Typography>
                            )}
                            <Button
                                startIcon={
                                    isFavourite ? (
                                        <Iconify icon="mdi:favorite" />
                                    ) : (
                                        <Iconify icon="mdi:favorite-border" />
                                    )
                                }
                                onClick={() => setFavourites((v) => ({ ...v, [target.id]: !v[target.id] }))}
                            >
                                Favourite
                            </Button>
                            <Box flex={1} />
                            <ButtonGroup>
                                <Button onClick={createReport} variant="contained" color="secondary">
                                    Report
                                </Button>
                                {!completed && (
                                    <Button onClick={complete} disabled={!canComplete} variant="contained">
                                        Complete
                                    </Button>
                                )}
                            </ButtonGroup>
                            <IconButton
                                onClick={() => replace(parent.children[index + 1])}
                                color="primary"
                                disabled={index === parent.children.length - 1}
                            >
                                <Iconify icon="mdi:arrow-right" />
                            </IconButton>
                        </ListItemBox>
                    </Box>
                </TreeBottomUser>
            </Stack>
        </Bound>
    )

    async function complete() {
        if (await sign()) {
            setLastCompleteTime(Date.now())
            await completeTask(shareLinkId, target.id, { from: "Companion" })
        }
    }

    async function createReport() {
        const maintenanceReport = await report()
        if (maintenanceReport) {
            reportIssue({
                ...maintenanceReport,
                shareLinkId,
                reportDate: new Date(),
                taskId: getCompletionKey(target.id),
                metadata: {
                    from: "Companion",
                },
            }).catch(console.error)

            await alert("Your report has been stored", "Success")
        }
    }

    function showSchedule() {
        const [word1, word2, word3] = target.linkId.split(" ")
        const url = new URL(window.location.href)

        navigate(
            `/sfg20/schedule/${word1}/${word2}/${word3}/details?keepActive=true&returnTo=${encodeURIComponent(
                url.pathname + url.search
            )}`
        )
    }
}
