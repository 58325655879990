import { Box, Stack, Typography } from "@mui/material"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { PrintableStack } from "lib/@components/pdf/printable-stack"
import { allParents } from "library/tree"
import { PlanRoot } from "library/tree/constants"

export function SchedulePDFHeader({ schedule, profile }) {
    const { currentGroup } = useBoundContext()
    const path = allParents(currentGroup, (c) => !c[PlanRoot])
        .reverse()
        .map("label")
        .join(" > ")
        .replace("Ready:", "")
        .replace("Live:", "")

    return (
        <Stack direction="row" justifyContent={"space-between"}>
            <PrintableStack spacing={2} mb={3}>
                <Box>
                    <Typography variant="h4" display="inline">
                        {schedule?.title}
                    </Typography>
                    <Typography variant="h5" display="inline" ml={1}>
                        v{schedule?.version}
                    </Typography>
                    <Typography variant="h5" display="inline" color="text.secondary" ml={1}>
                        #{schedule?.code}
                    </Typography>
                    {schedule?.retired && (
                        <Typography variant="h5" display="inline" color="text.secondary" ml={1}>
                            (Retired)
                        </Typography>
                    )}
                </Box>
                <Typography variant="h5">Company Name: {profile?.name}</Typography>
                <Typography variant="h5">Regime: {path}</Typography>
                <Typography variant="h5">Date Printed: {new Date().format("{dd}/{MM}/{yyyy}")}</Typography>
            </PrintableStack>
            {profile?.photoURL && (
                <Box sx={{ width: "150px" }}>
                    <img
                        style={{ display: "block", width: "100%", height: "auto" }}
                        src={profile?.photoURL}
                        id="company-image"
                    />
                </Box>
            )}
        </Stack>
    )
}
