import { useRef, useState } from "react"
import { Button, Menu, MenuItem, Portal, Typography } from "@mui/material"
import { bind } from "lib/@components/binding/bind"
import Iconify from "minimals-template/components/Iconify"

export const BoundStandardSelector = bind(<StandardSelector />, {
    extract: (v) => v,
})

export function StandardSelector({
    error,
    helperText,
    options,
    value,
    endIcon = <Iconify icon="mdi:arrow-down-drop" />,
    onChange,
    optionSx,
    menuSx,
    ...props
}) {
    const ref = useRef()
    const [anchor, setAnchor] = useState()
    return (
        <>
            <Button
                disabled={options.length < 2}
                ref={ref}
                onClick={() => setAnchor(ref.current)}
                endIcon={options.length > 1 && endIcon}
                {...props}
                data-cy="standard-selector-btn"
            >
                {value !== undefined ? options.find((o) => o.value === value)?.label ?? value?.label ?? value : "N/A"}
            </Button>
            {error && (
                <Typography variant="caption" color="red">
                    {helperText}
                </Typography>
            )}
            <Portal>
                <Menu
                    sx={{ zIndex: 3000, ...menuSx }}
                    data-cy="standard-selector-menu"
                    open={!!anchor}
                    anchorEl={anchor}
                    onClose={() => setAnchor(null)}
                >
                    {options.filter(Boolean).map(createOption)}
                </Menu>
            </Portal>
        </>
    )

    function createOption(option, index) {
        if (typeof option === "string") {
            return (
                <MenuItem sx={optionSx} selected={option === value} key={index} onClick={() => choose(option)}>
                    {option}
                </MenuItem>
            )
        }

        if (Array.isArray(option)) {
            return (
                <MenuItem sx={optionSx} selected={option[0] === value} key={index} onClick={() => choose(option[0])}>
                    {option[1] ?? option[0]}
                </MenuItem>
            )
        }

        return (
            <MenuItem sx={optionSx} selected={option.value === value} key={index} onClick={() => choose(option.value)}>
                {option.label}
            </MenuItem>
        )
    }

    function choose(value) {
        onChange(value)
        setAnchor(null)
    }
}
