import { useReferenceState } from "lib/@hooks/use-reference"

import { completed, CompletionsUpdate } from "companion/routes/schedule/use-task-completions"

import { getCompletionKey } from "library/get-completion-key"

export function completedToday(lastCompleteTime) {
    if (!lastCompleteTime) return false
    return Date.now() - +Date.create(lastCompleteTime).beginningOfDay() < 1000 * 60 * 60 * 24
}

export function useCompleted(task) {
    CompletionsUpdate.useRefresh()
    console.log("check", task.id)
    const [lastCompleteTime] = useReferenceState(getCompletionKey(task.id), 0)
    return task[completed] || completedToday(lastCompleteTime)
}
