import { Box, List, ListItem, ListItemText, Typography } from "@mui/material"

export const FEATURE_FLAG_REGIME_TASK_MANAGEMENT = "regime-task-management"

export const FEATURE_FLAG_REGIME_TASK_MANAGEMENT_DESCRIPTION = (
    // box needed to avoid layout issues
    <Box>
        <Typography variant="body1" paragraph>
            This feature flag hides the de-scoped features on the maintenance regime for the MVP of asset-mapping:
        </Typography>
        <FeatureFlagRegimeManagement />
    </Box>
)

function FeatureFlagRegimeManagement() {
    const items = ["Tasks tab", "Overview tab", "Scheduled Library Updates tab", "Variations tab"]

    return (
        <List>
            {items.map((item, index) => (
                <ListItem key={index}>
                    <ListItemText primary={`${index + 1}. ${item}`} />
                </ListItem>
            ))}
        </List>
    )
}
