import { gql } from "@apollo/client"
import { clone } from "lib/clone"
import noop from "lib/noop"
import useAsync from "lib/@hooks/useAsync"
import { indexTasksInSchedule } from "routes/schedule/lib/index-tasks-in-schedule"
import { mutate } from "lib/graphql/mutate"
import { query } from "lib/graphql/query"
import { stillEditing } from "minimals-template/components/lock/controller/still-editing"
import { ensureTaskIds } from "routes/schedule/edit/ensure-task-ids"

const methods = {
    draft,
    schedule,
    history,
    scheduleHistory,
    audit,
}

export const Methods = {
    draft: "draft",
    schedule: "schedule",
    draftHistory: "history",
    scheduleHistory: "scheduleHistory",
    audit: "audit",
}

async function draft(id) {
    const { definition } = await query(
        gql`
            query getDraftSchedule($id: String!) {
                definition: editSchedule(id: $id)
            }
        `,
        { id },
        { fetchPolicy: "network-only" }
    )
    if (!definition) return null
    return [
        indexTasksInSchedule({ ...clone(definition), $name: `Draft of ${definition.title}` }),
        async () => {
            await stillEditing(id)
        },
        async (content) => {
            await mutate(
                gql`
                    mutation write($id: String!, $content: JSONObject!) {
                        writeContent(content: $content, id: $id, unlock: true)
                    }
                `,
                { id, content },
                { fetchPolicy: "network-only" }
            )
        },
        `Draft of ${definition.title}`,
    ]
}

async function schedule(id) {
    const { definition } = await query(
        gql`
            query rawSchedule($id: String!) {
                definition: rawSchedule(id: $id)
            }
        `,
        { id }
    )
    return [
        indexTasksInSchedule(
            clone({
                ...definition,
                $name: `Published version of ${definition.title}`,
            })
        ),
        noop,
        noop,
        `Published version of ${definition.title}`,
    ]
}

async function history(id) {
    const { definition } = await query(
        gql`
            query getHistory($id: String!) {
                definition: draftHistoryEntry(entryId: $id)
            }
        `,
        { id }
    )
    return [
        indexTasksInSchedule(
            clone({
                ...definition.draft,
                $name: `Draft of ${definition.draft.title} as of ${new Date(definition.updated).long()}`,
            })
        ),
        noop,
        noop,
        `Draft of ${definition.draft.title} as of ${new Date(definition.updated).long()}`,
    ]
}

async function scheduleHistory(id) {
    const { definition } = await query(
        gql`
            query getScheduleHistoryRecord($id: String!) {
                definition: getScheduleHistoryRecord(id: $id)
            }
        `,
        { id }
    )
    return [
        indexTasksInSchedule(
            clone({
                ...definition,
                $name: `Version: ${definition.version}`,
            })
        ),
        noop,
        noop,
        `Version: ${definition.version}`,
    ]
}

async function audit(id) {
    const {
        getAudit: {
            payload: { content: definition },
        },
    } = await query(
        gql`
            query GetAudit($id: String!) {
                getAudit(id: $id) {
                    payload
                }
            }
        `,
        { id }
    )
    return [
        indexTasksInSchedule(
            clone({
                ...definition,
                $name: `Version: ${definition.version}`,
            })
        ),
        noop,
        noop,
        `Proposed Version: ${+definition.version + 1}`,
    ]
}

export function useScheduleByMethodId(idWithMethod, refId = "") {
    let method, id
    if (idWithMethod) {
        ;[method, id] = idWithMethod.split("!")
    }
    return useScheduleByMethod(method, id, refId)
}

export function useScheduleByMethod(method, id, refId = "") {
    return ensureTaskIds(useAsync(() => (methods[method] || (() => null))(id), undefined, [method, id, refId]))
}
